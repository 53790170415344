require([
    'jquery', 'autosize', 'parsleyLocale', 'parsley', 'select2Locale', 'locationMessages',  'niceSelect', 'select2'
  ],
  ($, autosize, parsleyLocale, parsley, select2Locale, locationMessages) => {

    'use strict';

	const forms = (() => {

        const setDefaults = () => {
            parsley.addMessages('pl', parsleyLocale);
            parsley.setLocale('pl');

            $.fn.select2.defaults.set('language', select2Locale);
            $.fn.select2.defaults.set('maximumSelectionSize', 1);
            $.fn.select2.defaults.set('width', null);
        };

		const parsleyConfig = {
			errorsWrapper: '<ul class="parsley-errors-list list-unstyled"></ul>',
			errorsContainer: el => {
				const $err = el.$element.parent();
				return $err;
			},
			classHandler: el => {
				return el.$element.parent();
			}
		};

		const textAreaGrow = () => {
			const autosizeSelector = '.input-box textarea, .field textarea, .autosize';
			autosize(document.querySelectorAll(autosizeSelector));
        };

        const validateForm = () => {
            const selector = '.form-validate';
            const $form = $(selector);
			if ($form.length) {
				$form.each((i, item) => {
					$(item).parsley(parsleyConfig);
				});
			}
        };

        const validatePhoneInput = () => {
            const selector = '[name="contact-form-phone"], [name="phone"], [name="telephone"]';
            $(selector).parsley({ type: 'digits' });
        };

		const init = () => {
            setDefaults();
			textAreaGrow();
            validateForm();
            validatePhoneInput();
		};

		return {
			init
		};

	})();

	// const newsletter = (() => {

	// 	const $newsletterForm = $('#newsletter-validate-detail');
	// 	const $newsletterPopup = $('#newsletter-popup');
	// 	const $newsletterFormPopup = $newsletterPopup.find('form');
	// 	const $subscribeMessage = $newsletterPopup.find('.subscribe-message');

	// 	if (!$newsletterForm.length) {
	// 		return;
	// 	}

	// 	const $modalBtn = $newsletterForm.find('button[type=submit]');
	// 	const $modal = $($modalBtn.data('mfp-src'));
	// 	const $submitBtn = $modal.find('button');
	// 	const $agreement = $('#newsletter-agreement');
	// 	const $agreementCheckbox = $modal.find('input[type=checkbox]');
	// 	const $checkboxes = $modal.find('input[type=checkbox]');

	// 	const copyAgreement = () => {
	// 		$agreement.val($agreementCheckbox.prop('checked'));
	// 	};

	// 	const copyCheckbox = () => {
	// 		const $checkboxes = $modal.find('input[type=checkbox]');
	// 		$checkboxes.map((i, checkbox) => {
	// 			const copySelector = checkbox.id.replace('-checkbox', '');
	// 			const $copyTo = $(`#${copySelector}`);
	// 			$copyTo.val(checkbox.checked);
	// 		});
	// 	};

	// 	const agreementValid = () => {
	// 		$.magnificPopup.open({
	// 			items: {
	// 				src: $modalBtn.data('mfp-src'),
	// 			},
	// 			callbacks: {
	// 				open: function() {
	// 					$subscribeMessage.removeClass('error').removeClass('success');
	// 					$newsletterPopup.find('.message').html('');
	// 					$agreementCheckbox.prop('checked', false);
	// 				}
	// 			},
	// 		});

	// 		return false;
	// 	};

	// 	const toggleMessageType = (isValid, message) => {
	// 		$subscribeMessage.toggleClass('error', !isValid);
	// 		$subscribeMessage.toggleClass('success', isValid);
	// 		$subscribeMessage.find('.message').html(message);
	// 	};

	// 	const subscribe = email => {
	// 		$.ajax({
	// 			url: '/advoxnewsletter/subscribe/ajax',
	// 			type: 'post',
	// 			data: {
	// 				email,
    //                 newsletterRegulations: $('#newsletter-regulations-checkbox:checked').val(),
    //                 newsletterAgreement: $('#newsletter-mail-checkbox:checked').val()
	// 			},
	// 			success: response => {
	// 				response = JSON.parse(response);
	// 				toggleMessageType(response.status === 'success', response.message);
	// 				$newsletterForm.find('input[type="email"]').val('');
	// 			}
	// 		});
	// 	};

	// 	const submitForm = () => {
	// 		const $modalForm = $modal.find('form');
	// 		if ($modalForm.length) {
	// 			$modalForm.parsley()
	// 				.whenValidate()
	// 				.done(() => {
	// 					const email = $('.newsletter-validate-detail #newsletter').val();

	// 					gReCaptchaNewsletterSubmit(() => {
	// 						console.log('x');
	// 						subscribe(email);
	// 					});
	// 				});
	// 		}
	// 	};

	// 	const addEvents = () => {
	// 		$agreementCheckbox.on('change', copyAgreement);
	// 		$checkboxes.on('change', copyCheckbox);
	// 		$newsletterForm.parsley().on('form:submit', agreementValid);
	// 		$submitBtn.on('click', submitForm);
	// 		$newsletterFormPopup.on('submit', e => e.preventDefault());
	// 	};

	// 	const init = () => {
	// 		addEvents();
	// 	};

	// 	return {
	// 		init
	// 	};

    // })();

    const categoryFiltersPatch = (() => {
        const $filters = $('.filter-options');

        const addEvents = () => {
            $filters.on('click', '.filter-options-content', (e) => {
                setTimeout(() => {
                    $(e.target).closest('.nice-select').addClass('open');
                });
            });
        };

		const init = () => {
            if (!$filters.length) {
                return;
            }
            addEvents();
        };

		return {
			init
		};

	})();

	const dropdown = (() => {
        const $select = $('select').not('.select-filter, [id*="region"], [id*="country"], .classic-select');
        const addEvents = () => $select.niceSelect();
		const init = () => {
            if (!$select.length) {
                return;
            }
            addEvents();
        };

		return {
			init
		};

	})();

	const selectFilter = (() => {

        const getSelect = (id) => {
            const label = $(`label[for=${id}]`);
            const suffix = label.length ? label.text().trim().toLowerCase() : '';
            const $element =  $(`#${id}`);
            const placeholder = `Wybierz ${suffix}`;
            const dropdownParent = $element.parent();

            return {
                $element,
                options: {
                    placeholder,
                    dropdownParent
                }
            };
        };

        const initSelect = (id) => {
            const { $element, options } = getSelect(id);

            if (!$element.length || !$element.select2) {
                return;
            }

            $element.select2(options);
        };

		const init = (selectIds) => {
            (selectIds || []).forEach((selectId) => {
                initSelect(selectId);
            });
        };

		return {
			init
		};

	})();

	const location = (() => {
		const url = 'https://maps.googleapis.com/maps/api/geocode/json';
		const API_KEY = 'AIzaSyDRQjPpRnqpnNsxhESu_iacNdGtIUGPSVs';
		const locationBtn = document.querySelector('.button-location');
		const locationInput = document.getElementById('location');
		const locationErrorType = 'locationError';
		const messages = locationMessages;

		const saveAddress = address => {
			localStorage.setItem('address', address);
		};

		const getSavedAddress = () => {
			return localStorage.getItem('address');
		};

		const setLocationInput = val => {
			locationInput.value = val;
		};

		const removeError = () => {
			$(locationInput).parsley().removeError(locationErrorType);
		};

		const getFromAddresses = addressList => {
			return addressList[0].hasOwnProperty('address_components') ?
				addressList[0]['address_components'][5]['long_name'] : false;
		};

		const inputReset = input => {
			$(input).parsley().reset();
		};

		const addInputMessage = (input, message, messageType) => {
			const $input = $(input).parsley();
			inputReset(input);
			$input.addError(messageType, {
				message: message,
				updateClass: true
			});
		};

		const locationError = () => {
			addInputMessage(locationInput, messages.location.error, locationErrorType);
		};

		const locationSuccess = (results) => {

			if (Array.isArray(results)) {
				const address = getFromAddresses(results);

				if (!getSavedAddress() || getSavedAddress() !== address) {
					saveAddress(address);
				}
				setLocationInput(address);
				inputReset(locationInput);
			} else {
				addInputMessage(locationInput, messages.location.error, locationErrorType);
			}

		};

		const getAddress = (position, success, error) => {
			if (!position.lat || !position.lng) {
				return error();
			}

			$.getJSON({
				method: 'get',
				url: url,
				data: {
					sensor: false,
					key: API_KEY,
					latlng: `${position.lat},${position.lng}`
				},
				success: (data) => {
					success(data.results);
				},
				error: () => {
					error();
				}
			});
		};

		const getLocation = (success, error) => {

			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(position => {
					const pos = {
						lat: position.coords.latitude,
						lng: position.coords.longitude
					};
					getAddress(pos, success, error);

				}, () => {
					success();
				});
			} else {
				error();
			}
		};

		const locationBtnClick = () => {
			const address = localStorage.getItem('address');

			if (address && address.length > 3) {
				if (locationInput.value.trim().length > 0) {
					addInputMessage(locationInput, messages.location.progress, locationError);
					getLocation(locationSuccess, locationError);
				} else {
					setLocationInput(address);
					inputReset(locationInput);
				}
			} else {
				addInputMessage(locationInput, messages.location.progress, locationError);
				getLocation(locationSuccess, locationError);
			}
		};

		const addEvents = () => {
			if (locationInput && locationBtn) {
				locationBtn.addEventListener('click', locationBtnClick);
				locationInput.addEventListener('keyup', removeError);
			}
		};

		const init = () => {
			addEvents();
		};

		return {
			init
		};
	})();

	forms.init();
	// newsletter.init();
	dropdown.init();
	selectFilter.init(['region_id', 'country']);
	location.init();
    categoryFiltersPatch.init();
});
